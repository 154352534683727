import React from 'react'
import { SvgProps } from './typings/SvgProps'

const Reload: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg
    {...rest}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 22.194 16.691"
  >
    <path
      d="M210,93.21a.793.793,0,0,0-1.11.127l-1.261,1.586h0A8.578,8.578,0,0,0,192.2,90.895a.794.794,0,0,0,1.276.943,6.914,6.914,0,0,1,5.621-2.854,7.024,7.024,0,0,1,6.993,6.383l-1.927-1.308a.793.793,0,1,0-.888,1.308l3.306,2.244a.793.793,0,0,0,1.07-.166l2.481-3.132a.793.793,0,0,0-.135-1.1Z"
      transform="translate(-188.102 -87.394)"
      fill={color}
    />
    <path
      d="M121.936,233.324a7,7,0,0,1-12.638-3.5l1.927,1.308a.793.793,0,1,0,.888-1.308l-3.3-2.244a.793.793,0,0,0-1.07.166l-2.482,3.132a.793.793,0,0,0,1.245.983l1.261-1.586a8.578,8.578,0,0,0,15.46,3.964.793.793,0,1,0-1.284-.928Z"
      transform="translate(-105.092 -221.093)"
      fill={color}
    />
  </svg>
)

export default Reload
