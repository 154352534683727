import React, { forwardRef } from 'react'

import ScrollableRow from './ScrollableRow'
import { IRow } from '../typing'
import { ScrollableColumn, ScrollableContainer } from '../styles'
import { ScrollableHeader } from './ScrollableHeader'
import { useTableFixed } from '../hook'

interface IScrollableColumnsProps {
  firstColumnRef: React.MutableRefObject<HTMLDivElement | null>
  lastColumnRef: React.MutableRefObject<HTMLDivElement | null>
  isMobile?: boolean
}
const ScrollableColumns = forwardRef<HTMLDivElement, IScrollableColumnsProps>(
  ({ firstColumnRef, lastColumnRef, isMobile }, ref) => {
    const {
      data: { scrollableColumns },
    } = useTableFixed()

    return (
      <ScrollableContainer ref={ref} data-testid="scroll-table-container">
        {scrollableColumns?.map(({ id: columnId, header, rows }, columnIndex) => {
          const { title } = header
          const [isFirstColumn, isLastColumn] = [
            !columnIndex,
            columnIndex === scrollableColumns.length - 1,
          ]
          const columnRef =
            (isFirstColumn && firstColumnRef) || (isLastColumn && lastColumnRef) || null

          return (
            <ScrollableColumn
              data-testid="table-fixed-column"
              ref={columnRef}
              key={`col-${columnIndex}`}
              hasCustomTitle={typeof title !== 'string'}
            >
              <ScrollableHeader
                columnIndex={columnIndex}
                header={header}
                rows={rows}
                isMobile={isMobile}
              />

              {(rows as IRow[]).map((row, rowIndex) => (
                <ScrollableRow
                  key={`col-${columnIndex}-table-fixed-${rowIndex}`}
                  header={header}
                  row={row}
                  rowIndex={rowIndex}
                  columnId={columnId ?? columnIndex}
                />
              ))}
            </ScrollableColumn>
          )
        })}
      </ScrollableContainer>
    )
  },
)

export default ScrollableColumns
