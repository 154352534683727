import React from 'react'
import { SvgProps } from './typings/SvgProps'

const DraftNotifications: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg
    {...rest}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    height={size}
    viewBox="0 0 24.504 22"
  >
    <defs>
      <clipPath id="clip-path">
        <path
          id="Subtraction_1"
          data-name="Subtraction 1"
          d="M7.416,19H0V0H15.657V3.69L11.3,8.255l2.837,2.973H7.416V19Z"
          transform="translate(0 0)"
          fill={color}
        />
      </clipPath>
    </defs>
    <g id="Group_163468" data-name="Group 163468" transform="translate(-14.248 -8.5)">
      <g id="documentos_menu" data-name="documentos menu" transform="translate(14.248 11.5)">
        <g id="Group_163032" data-name="Group 163032" transform="translate(2003 -870)">
          <g id="Group_2" data-name="Group 2" transform="translate(-1992.76 876.172)">
            <path
              id="Stroke_3"
              data-name="Stroke 3"
              d="M.609,6.823,6.849.582a1.992,1.992,0,0,1,2.815,0L10.68,1.6a1.99,1.99,0,0,1,0,2.815l-6.27,6.27a1.813,1.813,0,0,1-1.282.531H0L.078,8.059A1.812,1.812,0,0,1,.609,6.823Z"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              strokeWidth="1.5"
            />
            <path
              id="Stroke_5"
              data-name="Stroke 5"
              d="M0,0,3.813,3.813"
              transform="translate(5.901 1.547)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              strokeWidth="1.5"
            />
          </g>
          <g
            id="Mask_Group_1"
            data-name="Mask Group 1"
            transform="translate(-2003 870)"
            clipPath="url(#clip-path)"
          >
            <g id="Document" transform="translate(1.47 1.445)">
              <path
                id="Stroke_1"
                data-name="Stroke 1"
                d="M6.062.5H0"
                transform="translate(3.984 11.267)"
                fill="none"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="1.5"
              />
              <path
                id="Stroke_3-2"
                data-name="Stroke 3"
                d="M2.313.5H0"
                transform="translate(3.985 3.963)"
                fill="none"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="1.5"
              />
              <path
                id="Stroke_4"
                data-name="Stroke 4"
                d="M10.208,0,3.752,0C1.435.018,0,1.6,0,4.023v8.031c0,2.431,1.446,4.023,3.783,4.023l6.456,0c2.317-.015,3.753-1.6,3.753-4.021V4.023C13.992,1.592,12.545,0,10.208,0Z"
                fill="none"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="1.5"
              />
              <path
                id="Stroke_2"
                data-name="Stroke 2"
                d="M4.8.5H0"
                transform="translate(3.984 7.611)"
                fill="none"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="1.5"
              />
            </g>
          </g>
        </g>
      </g>
      <g id="Icon" transform="translate(-16.248 -235.5)">
        <circle
          id="Ellipse_1607"
          data-name="Ellipse 1607"
          cx="6"
          cy="6"
          r="6"
          transform="translate(43 244)"
          fill="#d93b2a"
        />
      </g>
    </g>
  </svg>
)

export default DraftNotifications
