import React, { useContext } from 'react'
import { EScrollDirection } from './constants'
import { TableFixedContext } from './context'

export const useTableFixed = (handleApiCall?: () => void) => {
  const state = useContext(TableFixedContext)

  const scrollableColumnsRef = React.useRef<HTMLDivElement | null>(null)
  const firstColumnRef = React.useRef<HTMLDivElement | null>(null)
  const lastColumnRef = React.useRef<HTMLDivElement | null>(null)

  const scrollBy = React.useCallback((direction: string) => {
    if (firstColumnRef.current) {
      const position = firstColumnRef.current.getBoundingClientRect().width

      if (scrollableColumnsRef.current) {
        scrollableColumnsRef.current?.scrollBy?.({
          left: direction === EScrollDirection.next ? position : -position,
          behavior: 'smooth',
        })
      }
    }
  }, [])

  React.useEffect(() => {
    if (!lastColumnRef?.current) return

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          handleApiCall?.()
        }
      },
      {
        threshold: 0.5,
      },
    )

    observer.observe(lastColumnRef.current)

    // eslint-disable-next-line consistent-return
    return () => {
      observer.disconnect()
    }
  }, [handleApiCall])

  return { ...state, scrollableColumnsRef, firstColumnRef, lastColumnRef, scrollBy }
}
