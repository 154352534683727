import React from 'react'
import {
  ICreateable,
  IData,
  IDeletableColumn,
  IEditable,
  IFilterable,
  ILoadPages,
  ISearchable,
} from './typing'
import { TableHeader, ScrollableColumns, FixedColumn } from './components'
import {
  BottomContainer,
  Container,
  EmptyContainer,
  FixedContainer,
  FixedTitle,
  Loader,
  LoadingContainer,
} from './styles'
import { TableFixedProvider } from './context'
import { useTableFixed } from './hook'
import { Button } from '../../atoms'

export interface ITableFixedProps
  extends IEditable,
    ICreateable,
    ISearchable,
    IFilterable,
    IDeletableColumn,
    ILoadPages {
  data: IData
  title?: string
  subTitle?: string
  isLoading?: boolean
  customLoader?: React.ReactNode
  isEmpty?: boolean
  handleApiCall?: () => void
  emptyMessage?: {
    title: string
    description?: string
    icon?: React.ReactNode
  }
}

export const TableFixed: React.FC<ITableFixedProps> = ({
  emptyMessage,
  addButtonLabel = 'Adicionar',
  canLoadPage,
  isLoadingPage,
  handleLoadPageCallback,
  handleAdd,
  handleApiCall,
  ...props
}) => {
  const { data, isLoading, isEmpty, customLoader } = props
  const { scrollableColumnsRef, firstColumnRef, lastColumnRef, scrollBy } = useTableFixed(
    handleApiCall,
  )

  return (
    <TableFixedProvider value={props}>
      <Container hasFixedColumn={!!data?.fixedColumn?.rows.length} data-testid="table-fixed">
        <TableHeader handleAdd={handleAdd} addButtonLabel={addButtonLabel} scrollBy={scrollBy} />

        {!!isLoading && (
          <Loader className="table-fixed-linear-loader" data-testid="table-fixed-loader" />
        )}
        {data && !isEmpty && (
          <>
            {!!data.fixedColumn?.rows.length && (
              <FixedContainer>
                <FixedTitle isLoading={isLoading}>{data?.fixedColumn?.title}</FixedTitle>
                {data?.fixedColumn?.rows?.map(({ label }, fixedColumnIndex) => (
                  <FixedColumn
                    key={`exam-${fixedColumnIndex}`}
                    label={label}
                    index={fixedColumnIndex}
                  />
                ))}
              </FixedContainer>
            )}

            <ScrollableColumns
              ref={scrollableColumnsRef}
              firstColumnRef={firstColumnRef}
              lastColumnRef={lastColumnRef}
            />
          </>
        )}
        {!!customLoader && !!isLoading && !props.data?.scrollableColumns?.length && (
          <LoadingContainer>{customLoader}</LoadingContainer>
        )}
        {isEmpty && (
          <EmptyContainer data-testid="table-fixed-empty-container">
            {emptyMessage?.icon && emptyMessage.icon}
            {emptyMessage?.title && <h3>{emptyMessage.title}</h3>}
            {emptyMessage?.description && <p>{emptyMessage.description}</p>}
          </EmptyContainer>
        )}
        {canLoadPage && (
          <BottomContainer>
            <Button
              data-testid="btn-table-fixed-load-page"
              isLoading={isLoadingPage}
              onClick={handleLoadPageCallback}
              variant="outlined"
            >
              Carregar mais
            </Button>
          </BottomContainer>
        )}
      </Container>
    </TableFixedProvider>
  )
}
