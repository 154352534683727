import React, { useState } from 'react'
import { theme } from '@cockpit/levi-ui/theme'
import { muiTheme } from '@cockpit/levi-ui/theme/theme'
import { useMediaQuery } from '@mui/material'
import { format } from 'date-fns'
import { Button, Icon } from '../../../atoms'
import { DateFilterState, ModalFilter } from './ModalFilter'
import { EScrollDirection } from '../constants'
import { ICreateable } from '../typing'
import {
  TableHeaderContainer,
  AddButton,
  SubTitle,
  PaginationContainer,
  Search,
  Title,
  TitleContainer,
  ContainerData,
  ContainerSelectedDates,
  TitleSelectedDates,
  ButtonClearFilter,
  SelectedDates,
  ContentHeaders,
} from '../styles'
import { useTableFixed } from '../hook'

interface ITableHeaderProps extends ICreateable {
  scrollBy: (direction: string) => void
}
const TableHeader: React.FC<ITableHeaderProps> = ({ handleAdd, addButtonLabel, scrollBy }) => {
  const [dateFilterSelected, setDateFilterSelected] = useState<DateFilterState>({
    from: '',
    to: '',
  })
  const {
    data,
    title,
    subTitle,
    isEmpty,
    handleFilterCallback,
    onSearchChange,
    onSearchBlur,
    onSearchEnter,
  } = useTableFixed()

  const formatedDate = {
    from: dateFilterSelected?.from
      ? format(new Date(dateFilterSelected?.from), 'LLL dd, yyyy')
      : '',
    to: dateFilterSelected?.to ? format(new Date(dateFilterSelected?.to), 'LLL dd, yyyy') : '',
  }

  const matchesMobile = useMediaQuery(muiTheme.breakpoints.down('sm'))
  const [showSearchBar, showPagination, showFilter, showTitle] = [
    !!onSearchChange || !!onSearchBlur || !!onSearchEnter,
    !matchesMobile && data && !isEmpty,
    !!handleFilterCallback,
    !!title || !!subTitle,
  ]

  const handleFilter = (dateFilter: DateFilterState) => {
    setDateFilterSelected(dateFilter)
    handleFilterCallback?.(dateFilter)
  }

  return (
    <>
      <TableHeaderContainer
        hasTitle={!!title && !showSearchBar}
        hasSubTitle={!!subTitle?.length}
        data-testid="table-header-container"
        className="table-fixed-header"
      >
        {showTitle && !showSearchBar && (
          <TitleContainer>
            {title && <Title>{title}</Title>}
            {subTitle && <SubTitle hasTitle={!!title}>{subTitle}</SubTitle>}
          </TitleContainer>
        )}
        <ContentHeaders>
          {showSearchBar && (
            <Search
              data-testid="table-fixed-search-input"
              onChange={e => {
                onSearchChange?.(e.target.value, data)
              }}
              onBlur={e => {
                onSearchBlur?.(e.target.value, data)
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  onSearchEnter?.((e.target as HTMLInputElement).value, data)
                  e.stopPropagation()
                  e.preventDefault()
                }
              }}
              icon="Search"
              placeholder="Pesquisar"
              iconOptions={{ position: 'end' }}
            />
          )}
          {showFilter && (
            <ModalFilter
              data-testid="table-fixed-modal-filter"
              filterCallback={dateFilter => handleFilter(dateFilter)}
            />
          )}
        </ContentHeaders>
        {handleAdd && (
          <AddButton
            size="medium"
            data-testid="table-fixed-add-button"
            variant="contained"
            onClick={handleAdd}
          >
            {addButtonLabel}
          </AddButton>
        )}
        {showPagination && (
          <PaginationContainer
            data-testid="table-fixed-container"
            hasTitle={!!title && !showSearchBar}
          >
            <Button
              data-testid="table-fixed-pagination-button-left"
              onClick={() => {
                scrollBy(EScrollDirection.previous)
              }}
            >
              <Icon icon="ChevronLeft" size={6} color={theme.colors.neutral[50]} />
            </Button>
            <Button
              data-testid="table-fixed-pagination-button-right"
              onClick={() => {
                scrollBy(EScrollDirection.next)
              }}
            >
              <Icon icon="ChevronRight" size={6} color={theme.colors.neutral[50]} />
            </Button>
          </PaginationContainer>
        )}
      </TableHeaderContainer>
      <ContainerData>
        {dateFilterSelected.from && (
          <ContainerSelectedDates data-testid="table-fixed-filter-to">
            Filtrado por:
            <SelectedDates>
              <TitleSelectedDates>{`${formatedDate.from} - ${formatedDate.to}`}</TitleSelectedDates>
              <ButtonClearFilter
                data-testid="table-fixed-button-clear-filter"
                onClick={() =>
                  handleFilter({
                    from: '',
                    to: '',
                  })
                }
              >
                <Icon icon="Close" size={10} />
              </ButtonClearFilter>
            </SelectedDates>
          </ContainerSelectedDates>
        )}
      </ContainerData>
    </>
  )
}

export default TableHeader
