import React from 'react'
import { SvgProps } from './typings/SvgProps'

const Nothing: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
<svg xmlns="http://www.w3.org/2000/svg" width={size} viewBox="0 0 40 40" {...rest}>
  <g transform="translate(1.25 1.25)">
    <path 
      d="M37.148,18.574A18.574,18.574,0,1,1,18.574,0,18.573,18.573,0,0,1,37.148,18.574Z" 
      fill="none" 
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.5}
    />
    <path
      d="M0,29.256,20.813,0" 
      transform="translate(11.787 2.167) rotate(13)" 
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.5}
    />
  </g>
</svg>
)

export default Nothing


