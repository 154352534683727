import zScoreMale from './male/zscore/heightForAgeZeroToTwentyFourMonths.json'
import percentilMale from './male/percentil/heightForAgeZeroToTwentyFourMonths.json'
import zScoreFemale from './female/zscore/heightForAgeZeroToTwentyFourMonths.json'
import percentilFemale from './female/percentil/heightForAgeZeroToTwentyFourMonths.json'
import { IDefaultLines, IPercentilSex, IZScoreSex } from '../typing'

const percentil: IPercentilSex = {
  female: percentilFemale,
  male: percentilMale,
}

const zScore: IZScoreSex = {
  female: zScoreFemale,
  male: zScoreMale,
}

export const heightForAgeZeroToTwentyFourMonths: IDefaultLines = {
  percentil,
  zScore,
}
