import React from 'react'
import * as icons from '@cockpit/levi-ui/icons'
import { SvgProps } from '@cockpit/levi-ui/icons/typings/SvgProps'

export type IconOptions =
  | 'AddCircle'
  | 'AddCircleSmall'
  | 'Alert'
  | 'AsideClose'
  | 'AsideOpen'
  | 'ArrowUp'
  | 'BackChevron'
  | 'CalendarDay'
  | 'CalendarEmpty'
  | 'CalendarExclude'
  | 'CalendarMonth'
  | 'CalendarToday'
  | 'CalendarUtilWeek'
  | 'CalendarWeek'
  | 'ChevronDown'
  | 'ChevronLeft'
  | 'ChevronRight'
  | 'ChevronUp'
  | 'ClearInput'
  | 'Clock'
  | 'Close'
  | 'Ellipsis'
  | 'Gear'
  | 'Location'
  | 'OneCircle'
  | 'Pencil'
  | 'Search'
  | 'Stethocospe'
  | 'Trash'
  | 'Check'
  | 'ChevronUpCircle'
  | 'ChevronDownCircle'
  | 'Bold'
  | 'Align'
  | 'Italic'
  | 'Items'
  | 'List'
  | 'TextColor'
  | 'TextSize'
  | 'Underline'
  | 'FacebookCircle'
  | 'Certificate'
  | 'CheckCircle'
  | 'GraphicComputer'
  | 'Headset'
  | 'InstagramCircle'
  | 'LinkedinCircle'
  | 'Envelope'
  | 'ProfileWarning'
  | 'CheckFilled'
  | 'Error'
  | 'Warning'
  | 'AttentionRounded'
  | 'AttentionTriangle'
  | 'Chat'
  | 'CallEnd'
  | 'EnterBlank'
  | 'Anex'
  | 'Send'
  | 'JumpOut'
  | 'LoadingAnimated'
  | 'MicroActive'
  | 'MicroDesactive'
  | 'Micro'
  | 'StopButton'
  | 'Video'
  | 'VideoOff'
  | 'DriverSupp'
  | 'Load'
  | 'Computer'
  | 'LightLocation'
  | 'Logout'
  | 'Link'
  | 'File'
  | 'Sliders'
  | 'DraftNotifications'
  | 'Draft'
  | 'ShareIcon'
  | 'Setting'
  | 'Blur'
  | 'Nothing'
  | 'Reload'

type IIcons = {
  [key: string]: React.FC<SvgProps>
}

const IconList: IIcons = icons

export default IconList
