import { theme } from '@cockpit/levi-ui/theme'
import styled from '@emotion/styled'

export const Container = styled.div<{ hasData: boolean }>`
  .table-fixed-field-edited > input[value],
  .table-fixed-field-edited > input:disabled {
    background-color: ${theme.colors.yellow[20]} !important;
    border-radius: 64px;
    margin: 0px 24px;
  }

  .table-fixed-linear-loader {
    ${({ hasData }) => !hasData && 'display: none !important;'}
  }
`
