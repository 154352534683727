import React from 'react'
import { SvgProps } from './typings/SvgProps'

const Setting: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    viewBox='0 0 17 19'
    fill={color}
    {...rest} 
  >
    <g transform='translate(0.737 0.625)'>
      <g>
        <path
          d='M15.557,4.789,15,3.822a1.689,1.689,0,0,0-2.315-.631h0a1.681,1.681,0,0,1-2.315-.607,1.652,1.652,0,0,1-.227-.82h0A1.723,1.723,0,0,0,9.667.519,1.69,1.69,0,0,0,8.449,0H7.336a1.682,1.682,0,0,0-1.2.5,1.714,1.714,0,0,0-.492,1.211h0A1.706,1.706,0,0,1,3.948,3.4a1.613,1.613,0,0,1-.812-.23h0A1.689,1.689,0,0,0,.82,3.805l-.593.984A1.723,1.723,0,0,0,.844,7.127h0a1.717,1.717,0,0,1,.849,1.484A1.717,1.717,0,0,1,.844,10.1h0a1.715,1.715,0,0,0-.617,2.329h0l.561.976a1.7,1.7,0,0,0,1.021.818A1.684,1.684,0,0,0,3.1,14.065h0a1.695,1.695,0,0,1,2.535,1.443h0a1.706,1.706,0,0,0,1.7,1.714H8.449a1.706,1.706,0,0,0,1.7-1.706h0a1.714,1.714,0,0,1,.495-1.214,1.682,1.682,0,0,1,1.2-.5,1.718,1.718,0,0,1,.812.23h0a1.689,1.689,0,0,0,2.315-.623h0l.585-.984a1.715,1.715,0,0,0-.617-2.337h0a1.715,1.715,0,0,1-.617-2.337,1.673,1.673,0,0,1,.617-.623h0A1.723,1.723,0,0,0,15.557,4.8h0Z'
          transform='translate(0 0)'
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          strokeWidth={1.25}
        />
        <ellipse
          cx='2.34'
          cy='2.362'
          rx='2.34'
          ry='2.362'
          transform='translate(5.556 6.249)'
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          strokeWidth={1.25}
        />
      </g>
    </g>
  </svg>
)

export default Setting
