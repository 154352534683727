import React from 'react'
import { SvgProps } from './typings/SvgProps'

const ShareIcon: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} {...rest} viewBox="0 0 21 17" >
    <g transform="translate(18397.125 10493.125)">
      <path 
        d="M.5,3.25A2.25,2.25,0,0,1,2.75,1h15.5A2.25,2.25,0,0,1,20.5,3.25v11.5A2.25,2.25,0,0,1,18.25,17H2.75A2.25,2.25,0,0,1,.5,14.75Z" 
        transform="translate(-18397 -10493.5)" 
        fill="none" 
        stroke={color} 
        strokeWidth={1.25}
      />
      <path d="M5.5,11v0Zm0-10L1,5.5Zm0,0L10,5.5Z"
        transform="translate(-18392 -10490.5)" 
        fill={color} 
      />
      <path 
        d="M5.5,11V1m0,0S3.287,3.043,2.162,4.168M5.5,1,8.773,4.168" 
        transform="translate(-18392 -10490.5)" 
        fill="none" 
        stroke={color} 
        strokeLinecap="round"
        strokeLinejoin="round" 
        strokeWidth={1.25}
      />
    </g>
  </svg>
)

export default ShareIcon


