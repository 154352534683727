import React, { FC, ReactChildren, ReactNode } from 'react'
import { DialogActions, DialogContent, DialogTitle, Fade, IconButton, Slide } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import { Dialog } from './styles'
import { Icon } from '../../atoms'

export type IModal = {
  open: boolean
  title?: string | ReactNode
  onClose?: () => void
  size?: 'sm' | 'md' | 'lg'
  children: string | ReactChildren | Element | Element[] | JSX.Element | JSX.Element[]
  actions?: ReactNode
  disableEscapeKeyDown?: boolean
  enabledSlide?: boolean
  fullScreen?: boolean
  noLine?: boolean
}

const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>,
  ) => {
    return <Slide direction="up" ref={ref} {...props} />
  },
)

const TransitionFade = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>,
  ) => {
    return <Fade ref={ref} {...props} />
  },
)

const Modal: FC<IModal> = ({
  size = 'md',
  enabledSlide,
  children,
  title,
  actions,
  noLine,
  ...rest
}) => (
  <Dialog
    enabledSlide={enabledSlide}
    noLine={noLine}
    fullWidth
    TransitionComponent={enabledSlide ? Transition : TransitionFade}
    maxWidth={size}
    data-testid="levi-modal-component"
    {...rest}
  >
    {(title || rest.onClose) && (
      <DialogTitle>
        {typeof title === 'string' ? (
          <span className="levi-modal-title-string">{title}</span>
        ) : (
          title
        )}
        {rest.onClose && !enabledSlide && (
          <IconButton
            aria-label="close"
            onClick={rest.onClose}
            data-testid="levi-modal-close-button"
          >
            <Icon icon="Close" size={14} />
          </IconButton>
        )}
      </DialogTitle>
    )}
    <DialogContent>{children}</DialogContent>
    {actions && <DialogActions>{actions}</DialogActions>}
  </Dialog>
)

export default Modal
