import React, { useEffect, useRef, useState } from 'react'
import { useMediaQuery } from '@mui/material'
import { muiTheme, theme } from '@cockpit/levi-ui/theme/theme'
import { Tooltip, Icon, MenuDropdown } from '../../../atoms'
import {
  ButtonIconDelete,
  ContainerTitleHeader,
  ContainerTitleLeft,
  DotButton,
  InfoLabel,
  MobileButton,
  ModalEdit,
  ScrollableHeader as ScrollableHeaderContainer,
  ScrollableHeaderContent,
  ScrollableHeaderDescription,
  ScrollableHeaderTitle,
  ScrollableHeaderTitleContainer,
  TextTooltip,
  TitleModal,
  TitleModalHeader,
  TitleRegistrationManual,
} from '../styles'
import { IDeletableColumn, IHeader, IRow } from '../typing'
import { useTableFixed } from '../hook'

interface IScrollableHeaderProps extends IDeletableColumn {
  header: IHeader
  rows: IRow[]
  isMobile?: boolean
  columnIndex: number
}
export const ScrollableHeader: React.FC<IScrollableHeaderProps> = ({
  header,
  rows,
  columnIndex,
  isMobile,
}) => {
  const { deletable, description, info, title } = header
  const { isLoading, onHandleDeleteColumnCallback } = useTableFixed()

  const iconRef = useRef<HTMLButtonElement>(null)
  const [openModal, setOpenModal] = useState(false)
  const [menuHandler, setMenuHandler] = useState<{
    open: boolean
    action: 'delete' | 'none'
  }>({
    open: false,
    action: 'none',
  })

  const matchesMobile = useMediaQuery(muiTheme.breakpoints.down('sm'))
  const showDeleteColumn = !!deletable && !!onHandleDeleteColumnCallback

  const handleToggleMenu = () => setMenuHandler({ ...menuHandler, open: !menuHandler.open })
  const handleOnChange = (header: IHeader) => {
    onHandleDeleteColumnCallback?.({ header })
  }

  const menuOptions = [
    {
      text: 'Excluir coluna',
      startIcon: <Icon icon="Trash" size={16} />,
      onClick: () => {
        setMenuHandler({ action: 'delete', open: false })
        handleOnChange(header)
      },
      disabled: false,
    },
  ]

  const [showHeaderTooltip, toggleHeaderTooltip] = useState(false)

  useEffect(() => {
    window.addEventListener('touchstart', () => toggleHeaderTooltip(false))
    window.addEventListener('touchmove', () => toggleHeaderTooltip(false))

    return () => {
      window.removeEventListener('touchstart', () => toggleHeaderTooltip(false))
      window.removeEventListener('touchmove', () => toggleHeaderTooltip(false))
    }
  }, [])

  return (
    <ScrollableHeaderContainer
      data-testid="scroll-table-header-container"
      isLoading={isLoading}
      lastIndex={columnIndex === rows.length - 1}
      isMenuOpened={menuHandler.open}
    >
      {isMobile || matchesMobile ? (
        <>
          <MobileButton
            onClick={() => {
              setOpenModal(!openModal)
              if (info?.show && !showDeleteColumn) {
                toggleHeaderTooltip(!showHeaderTooltip)
              }
            }}
            data-testid="table-fixed-data-modal"
          >
            <ScrollableHeaderContent>
              <ScrollableHeaderTitleContainer>
                {info && info.show && (
                  <Tooltip
                    open={isMobile || matchesMobile ? showHeaderTooltip : undefined}
                    title={<TextTooltip>{info.description}</TextTooltip>}
                    element={<InfoLabel>{info.label}</InfoLabel>}
                    placement="bottom"
                    componentsProps={{
                      popper: {
                        modifiers: [
                          {
                            name: 'offset',
                            options: {
                              offset: [0, 4],
                            },
                          },
                        ],
                      },
                    }}
                  />
                )}
                {typeof title === 'string' ? (
                  <ScrollableHeaderTitle>{title}</ScrollableHeaderTitle>
                ) : (
                  title
                )}
              </ScrollableHeaderTitleContainer>
              <ScrollableHeaderDescription>{description}</ScrollableHeaderDescription>
            </ScrollableHeaderContent>
          </MobileButton>
          {showDeleteColumn && (
            <ModalEdit
              title=""
              onClose={() => setOpenModal(!openModal)}
              open={openModal}
              fullScreen
              enabledSlide
            >
              <ContainerTitleHeader>
                <ContainerTitleLeft>
                  <TitleModalHeader>Excluir valor</TitleModalHeader>
                  {header?.title && <TitleModal>{header?.title}</TitleModal>}
                </ContainerTitleLeft>

                <ContainerTitleLeft>
                  {header.info?.description && (
                    <TitleRegistrationManual>{header.info?.description}</TitleRegistrationManual>
                  )}
                  <ButtonIconDelete
                    data-testid="button-delete-icon"
                    onClick={() => {
                      setOpenModal(!openModal)
                      handleOnChange(header)
                    }}
                  >
                    <Icon size={16} icon="Trash" color={theme.colors.neutral[80]} />
                  </ButtonIconDelete>
                </ContainerTitleLeft>
              </ContainerTitleHeader>
            </ModalEdit>
          )}
        </>
      ) : (
        <ScrollableHeaderContent>
          <ScrollableHeaderTitleContainer>
            {info && info.show && (
              <Tooltip
                title={<TextTooltip>{info.description}</TextTooltip>}
                element={<InfoLabel>{info.label}</InfoLabel>}
                placement="bottom"
                componentsProps={{
                  popper: {
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [0, 4],
                        },
                      },
                    ],
                  },
                }}
              />
            )}
            {typeof title === 'string' ? (
              <ScrollableHeaderTitle>{title}</ScrollableHeaderTitle>
            ) : (
              title
            )}
          </ScrollableHeaderTitleContainer>
          <ScrollableHeaderDescription>{description}</ScrollableHeaderDescription>
        </ScrollableHeaderContent>
      )}

      {showDeleteColumn && (
        <>
          {!matchesMobile && (
            <DotButton
              onClick={handleToggleMenu}
              ref={iconRef}
              disableRipple
              data-testid="table-fixed-item-dot-button-data-delete-column"
              sx={{
                color: 'info.main',
                ':hover': {
                  color: 'primary.main',
                },
                transform: 'rotate(90deg)',
              }}
            >
              <Icon size={16} icon="Ellipsis" />
            </DotButton>
          )}
          <MenuDropdown
            key={`col-dot-menu-dropdown-${columnIndex}`}
            options={menuOptions}
            anchorEl={iconRef?.current}
            open={menuHandler.open}
            onClose={handleToggleMenu}
          />
        </>
      )}
    </ScrollableHeaderContainer>
  )
}
