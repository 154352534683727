import React, { useState } from 'react'
import { useMediaQuery } from '@mui/material'
import { muiTheme } from '@cockpit/levi-ui/theme/theme'
import Button from '../../../atoms/Button/Button'
import Field from './Field'
import {
  Container,
  FixedContainer,
  FixedRow,
  FixedRowContainer,
  FixedRowDescription,
  FixedRowTitle,
  FixedTitle,
  ModalStyled,
  ScrollableColumn,
  ScrollableContainer,
  ScrollableHeader,
  ScrollableHeaderTitle,
  ScrollableRowContainer,
} from '../styles'
import { useTableFixed } from '../hook'

export interface IFixedColumnProps {
  label: string | React.ReactNode
  index: number
  isMobile?: boolean
}

const FixedColumn: React.FC<IFixedColumnProps> = ({ label, index, isMobile }) => {
  const [openModal, setOpenModal] = useState(false)
  const matchesMobile = useMediaQuery(muiTheme.breakpoints.down('sm'))
  const { data } = useTableFixed()
  const { rowsOptions } = data
  if (isMobile || matchesMobile) {
    return (
      <FixedRowContainer>
        <Button onClick={() => setOpenModal(true)} data-testid="table-fixed-button-modal">
          <FixedRowTitle>{label}</FixedRowTitle>
        </Button>

        {openModal && (
          <ModalStyled
            data-testid="table-fixed-modal-mobile"
            title=""
            onClose={() => setOpenModal(!openModal)}
            open={openModal}
            fullScreen
          >
            <Container hasFixedColumn={!!data?.fixedColumn?.rows.length}>
              <>
                <FixedContainer>
                  <FixedTitle>Data</FixedTitle>
                  {data?.scrollableColumns?.map(({ header: { title, description } }) => (
                    <FixedRowContainer>
                      <FixedRowTitle>
                        <FixedRowTitle>{title}</FixedRowTitle>
                        <FixedRowDescription>{description ?? ''}</FixedRowDescription>
                      </FixedRowTitle>
                    </FixedRowContainer>
                  ))}
                </FixedContainer>

                <ScrollableContainer>
                  <ScrollableColumn data-testid="table-fixed-column">
                    <ScrollableHeader>
                      <ScrollableHeaderTitle>Resultados</ScrollableHeaderTitle>
                    </ScrollableHeader>
                    {data?.scrollableColumns.map(({ rows }) => (
                      <ScrollableRowContainer>
                        <Field
                          value={rows?.[index]?.value ?? '-'}
                          mask={
                            rows?.[index]?.value === '-'
                              ? undefined
                              : rows?.[index]?.mask ?? rowsOptions?.[index]?.mask
                          }
                          tooltipOnEllipsis
                          disabled
                        />
                      </ScrollableRowContainer>
                    ))}
                  </ScrollableColumn>
                </ScrollableContainer>
              </>
            </Container>
          </ModalStyled>
        )}
      </FixedRowContainer>
    )
  }

  return (
    <FixedRowContainer>
      <FixedRow>
        <FixedRowTitle>{label}</FixedRowTitle>
      </FixedRow>
    </FixedRowContainer>
  )
}

export default FixedColumn
