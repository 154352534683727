import React, { useState } from 'react'
import { Typography, useMediaQuery } from '@mui/material'
import { muiTheme, theme } from '@cockpit/levi-ui/theme/theme'
import { Icon, Button } from '../../../atoms'
import DatePicker from '../../DatePicker/DatePicker'
import {
  ButtonFilter,
  ContainerDates,
  ContainerFilterModal,
  ContentDates,
  ContentFilterHeader,
  DateLabel,
  FooterDates,
  ModalStyled,
  PopoverFilter,
} from '../styles'

export const labels = {
  filter: 'Filtrar',
  title: 'Aplicar filtro por período',
  dateFilter: {
    from: 'De: ',
    to: 'Até: ',
  },
  cancel: 'Cancelar',
  apply: 'Aplicar',
  clearFilters: 'Limpar filtros',
}

export type DateFilterState = { from: string; to: string }
export interface IModalFilter {
  filterCallback: (filter: DateFilterState) => void
  isMobile?: boolean
}
export const ModalFilter: React.FC<IModalFilter> = ({ filterCallback, isMobile }) => {
  const matchesMobile = useMediaQuery(muiTheme.breakpoints.down('sm'))

  const [dateFilter, setDateFilter] = useState<DateFilterState>({ from: '', to: '' })
  const [isValidDate, setIsValidDate] = useState<{ from: boolean; to: boolean }>({
    from: true,
    to: true,
  })

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const clearFilters = () => {
    setDateFilter({ from: '', to: '' })
    setIsValidDate({ from: true, to: true })
  }

  const handleClose = () => {
    setAnchorEl(null)
    clearFilters()
  }

  const handleClick = (event?: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event?.currentTarget || null)
  }

  const formatSelectedDate = (date: string): string => {
    const [day, month, year] = date.split('/')
    const parsedDate = `${year}-${month}-${day}`

    if (!Date.parse(parsedDate)) return ''

    return parsedDate
  }

  const handleApply = () => {
    const formatedDates = {
      from: formatSelectedDate(dateFilter.from),
      to: formatSelectedDate(dateFilter.to),
    }
    const isValid = {
      from: Boolean(formatedDates?.from.length > 0 && Date.parse(formatedDates.from)),
      to: Boolean(formatedDates?.to.length > 0 && Date.parse(formatedDates.to)),
    }
    setIsValidDate({ ...isValid })

    if (isValid.from && isValid.to) {
      filterCallback(formatedDates)
      setIsValidDate({ from: true, to: true })
      handleClose()
      clearFilters()
    }
  }

  return (
    <div>
      <ButtonFilter variant="outlined" onClick={handleClick}>
        <Icon icon="Sliders" size={6} color={theme.colors.neutral[50]} />
        {!matchesMobile && labels.filter}
      </ButtonFilter>

      {matchesMobile || isMobile ? (
        <ModalStyled
          data-testid="exam-result-table-modal-mobile"
          title={labels.title}
          onClose={handleClose}
          open={open}
          fullScreen
        >
          <ContainerFilterModal>
            <ContentFilterHeader>
              <ContentDates>
                <DateLabel>{labels.dateFilter.from}</DateLabel>
                <DatePicker
                  value={dateFilter.from}
                  onChange={dateValue => {
                    setDateFilter({ ...dateFilter, from: dateValue })
                  }}
                  placeholder="DD/MM/AAAA"
                  error={!isValidDate.from}
                  message={!isValidDate.from ? 'Data inválida' : ''}
                />
              </ContentDates>

              <ContentDates>
                <DateLabel>{labels.dateFilter.to}</DateLabel>
                <DatePicker
                  value={dateFilter.to}
                  onChange={dateValue => {
                    setDateFilter({ ...dateFilter, to: dateValue })
                  }}
                  placeholder="DD/MM/AAAA"
                  error={!isValidDate.to}
                  message={!isValidDate.to ? 'Data inválida' : ''}
                />
              </ContentDates>
            </ContentFilterHeader>

            <FooterDates>
              <div>
                <Button size="large" variant="outlined" onClick={handleClose}>
                  {labels.cancel}
                </Button>
                <Button size="large" variant="contained" onClick={handleApply}>
                  {labels.apply}
                </Button>
              </div>
              <Button variant="text" onClick={clearFilters}>
                {labels.clearFilters}
              </Button>
            </FooterDates>
          </ContainerFilterModal>
        </ModalStyled>
      ) : (
        <PopoverFilter
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: -200,
          }}
        >
          <ContainerDates>
            <Typography sx={{ p: 1 }}> {labels.title}</Typography>
            <div>
              <ContentDates>
                <DateLabel> {labels.dateFilter.from}</DateLabel>
                <DatePicker
                  value={dateFilter.from}
                  onChange={dateValue => setDateFilter({ ...dateFilter, from: dateValue })}
                  placeholder="DD/MM/AAAA"
                  error={!isValidDate.from}
                  message={!isValidDate.from ? 'Data inválida' : ''}
                />
              </ContentDates>
              <ContentDates>
                <DateLabel> {labels.dateFilter.to}</DateLabel>
                <DatePicker
                  value={dateFilter.to}
                  onChange={dateValue => setDateFilter({ ...dateFilter, to: dateValue })}
                  placeholder="DD/MM/AAAA"
                  error={!isValidDate.to}
                  message={!isValidDate.to ? 'Data inválida' : ''}
                />
              </ContentDates>
            </div>
            <FooterDates>
              <ButtonFilter variant="text" onClick={clearFilters}>
                {labels.clearFilters}
              </ButtonFilter>
              <ButtonFilter variant="outlined" onClick={handleClose}>
                {labels.cancel}
              </ButtonFilter>
              <ButtonFilter
                variant="contained"
                onClick={handleApply}
                data-testid="button-filter-apply"
              >
                {labels.apply}
              </ButtonFilter>
            </FooterDates>
          </ContainerDates>
        </PopoverFilter>
      )}
    </div>
  )
}
