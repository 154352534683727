import React from 'react'
import { SvgProps, WithSecondaryColor } from './typings/SvgProps'
import { theme } from 'theme'

const Blur: React.FC<SvgProps & WithSecondaryColor> = ({ color = 'currentColor', size, secondaryColor = theme.colors.neutral[10], ...rest}) => (
<svg xmlns="http://www.w3.org/2000/svg" width={size} viewBox="0 0 24 24" {...rest}>
  <g transform="translate(2451 9899)">
    <rect
      width={24} 
      height={24} 
      transform="translate(-2451 -9899)" 
      fill={secondaryColor} 
      opacity="0.001"
    />
    <g transform="translate(-2451 -9897.475)">
      <path 
        d="M120.112,224.977v-2.384L126.7,216h2.384ZM120,220.32v-2.384L121.936,216h2.384Zm14.784-.477q-.281-.309-.589-.6a4.035,4.035,0,0,0-.673-.519L136.242,216h2.384Zm-11.978,9.594,2.16-2.16a5.422,5.422,0,0,0,.407.561,4.444,4.444,0,0,0,.463.477l-.533.14q-.645.2-1.262.449T122.805,229.437Zm13.409-6.228a8.275,8.275,0,0,0-.168-.982,4.232,4.232,0,0,0-.337-.926l4.488-4.488v2.413Zm-6.424-5.526L131.473,216h2.384l-1.964,1.964a6.356,6.356,0,0,0-.884-.21,5.722,5.722,0,0,0-.912-.07Zm-9.79,12.2V227.5l3.927-3.927v.281a7.457,7.457,0,0,0,.056.926,3.5,3.5,0,0,0,.224.87Zm18.8.309a3.183,3.183,0,0,0-.729-.5q-.393-.2-.785-.365l2.917-2.917v2.384Zm-3.254-1.515q-.309-.084-.6-.182t-.6-.182a4.556,4.556,0,0,0,.94-1.094,7.13,7.13,0,0,0,.631-1.318l4.292-4.292v2.413Z" 
        transform="translate(-118.099 -216.05)" 
        fill={color} 
      />
      <path 
        d="M20,21V19a4,4,0,0,0-4-4H8a4,4,0,0,0-4,4v2" 
        fill="none" 
        stroke={color} 
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
      <circle 
        cx="4"
        cy="4"
        r="4"
        transform="translate(8 4)"
        fill="none"
        stroke={color} 
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
    </g>
  </g>
</svg>
)

export default Blur

