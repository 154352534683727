export enum EAnthropometryChartSex {
  male = 'male',
  female = 'female',
}

export enum EAnthropometryChartType {
  zScore = 'zScore',
  percentil = 'percentil',
}

export enum EAnthropometryChartDefaultLines {
  weightForAgeTen = 'weightForAgeTen',
  weightForAgeFive = 'weightForAgeFive',
  imcForAge = 'imcForAge',
  heightForAge = 'heightForAge',
  headCircumferenceForAge = 'headCircumferenceForAge',
  imcForAgeZeroToTwentyFourMonths = 'imcForAgeZeroToTwentyFourMonths',
  heightForAgeZeroToTwentyFourMonths = 'heightForAgeZeroToTwentyFourMonths',
  heightForAgeTwoToFiveYears = 'heightForAgeTwoToFiveYears',
  heightForAgeZeroToFiveYears = 'heightForAgeZeroToFiveYears',
  imcForAgeTwoToFiveYears = 'imcForAgeTwoToFiveYears',
  imcForAgeZeroToFiveYears = 'imcForAgeZeroToFiveYears',
}

interface IPercentil {
  p3: number
  month: number
  p85: number
  p97: number
  p15: number
  p50: number
}

interface IPercentilSex {
  female: IPercentil[]
  male: IPercentil[]
}

interface IZScore {
  month: number
  '1': number
  '0': number
  '3': number
  '2': number
  '-1': number
  '-3': number
  '-2': number
}

interface IZScoreSex {
  female: IZScore[]
  male: IZScore[]
}

interface IDefaultLines {
  percentil: IPercentilSex
  zScore: IZScoreSex
}

interface IPatientData {
  month: number
  patient: number
}

type EGetDefaultLines = ({
  sex,
  type,
  defaultLines,
}: {
  sex: EAnthropometryChartSex
  type: EAnthropometryChartType
  defaultLines: EAnthropometryChartDefaultLines
}) => IPercentil[] | IZScore[]

interface IAnthropometryChart {
  unit?: string
  /**
   * Define a largura do gráfico, default 100% de largura
   */
  width?: number | string
  /**
   * Número passado para dimensionar a altura pela largura do gráfico, default de 1.5
   */
  aspect?: number
  xAxisShort?: boolean
  sex: EAnthropometryChartSex
  type: EAnthropometryChartType
  defaultLines: EAnthropometryChartDefaultLines
  patientData: IPatientData[]
  showMonthLine?: boolean
}

export type {
  IAnthropometryChart,
  EGetDefaultLines,
  IPercentil,
  IPercentilSex,
  IZScore,
  IZScoreSex,
  IDefaultLines,
  IPatientData,
}
