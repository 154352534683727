import { IData } from './typing'
import {
  IData as ITableFixedData,
  IFixedColumn,
  IHeader,
  IScrollableColumn,
} from '../../molecules/TableFixed/typing'

const formatDate = (date: string) => {
  const [year, month, day] = date.split('-')
  return `${day}/${month}/${year}`
}

export const dataDTO = (examData: IData | undefined): ITableFixedData => {
  const fixedColumn: IFixedColumn = {
    title: 'Exame',
    rows:
      examData?.examsNames.map(examName => ({
        label: examName,
      })) ?? [],
  }

  const scrollableColumns: IScrollableColumn[] =
    examData?.examResults.map(({ laboratory, date, editable, results }) => ({
      header: {
        id: laboratory?.id,
        title: date ? formatDate(date) : '',
        description: laboratory?.description,
        deletable: editable,
        info: editable
          ? {
              show: true,
              label: 'M',
              description: 'Registro Manual',
            }
          : undefined,
      } as IHeader,
      rows: results.map(result => ({
        ...result,
        disabled: !result?.value,
      })),
    })) ?? []

  return {
    fixedColumn,
    scrollableColumns,
  }
}
