import React from 'react'
import { SvgProps } from './typings/SvgProps'

const Sliders: React.FC<SvgProps> = ({ color = 'currentColor', size, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill={color} width={size} {...rest} viewBox="0 0 16 14">
    <path
      d="M2.75 12.75v-4m0-3v-5m6 12v-6m0-3v-3m5 12v-3m0-3v-6m-13 8h4m2-5h4m1 6h4"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
  </svg>
)

export default Sliders
