import React, { useContext, useMemo } from 'react'
import { ICreateable, IData, IDeletableColumn, IEditable, IFilterable, ISearchable } from './typing'

export const INITIAL_STATE: ITableFixedState = {
  data: { scrollableColumns: [] },
  title: '',
  subTitle: '',
  isEmpty: false,
  isLoading: false,
}

export interface ITableFixedState
  extends IEditable,
    ICreateable,
    IFilterable,
    ISearchable,
    IDeletableColumn {
  data: IData
  title?: string
  subTitle?: string
  isLoading?: boolean
  isEmpty?: boolean
}
export const TableFixedContext = React.createContext<ITableFixedState>(INITIAL_STATE)

interface Props {
  children?: React.ReactNode
  value: ITableFixedState
}
export const TableFixedProvider = ({ children, value }: Props) => {
  const state = useContext(TableFixedContext)

  const memoizedValue = useMemo(() => ({ ...state, ...value }), [state, value])

  return <TableFixedContext.Provider value={memoizedValue}>{children}</TableFixedContext.Provider>
}
