/* eslint-disable @typescript-eslint/no-unsafe-return */
import monthsToYears from 'date-fns/monthsToYears'
import {
  headCircumferenceForAge,
  weightForAgeFive,
  weightForAgeTen,
  imcForAge,
  heightForAge,
  imcForAgeZeroToTwentyFourMonths,
  heightForAgeZeroToTwentyFourMonths,
  heightForAgeTwoToFiveYears,
  heightForAgeZeroToFiveYears,
  imcForAgeTwoToFiveYears,
  imcForAgeZeroToFiveYears,
} from './data'
import { EGetDefaultLines, EAnthropometryChartDefaultLines } from './typing'

export const getDefaultLines: EGetDefaultLines = ({ sex, type, defaultLines }) => {
  const lines = {
    headCircumferenceForAge,
    weightForAgeTen,
    weightForAgeFive,
    imcForAge,
    heightForAge,
    heightForAgeZeroToTwentyFourMonths,
    heightForAgeTwoToFiveYears,
    heightForAgeZeroToFiveYears,
    imcForAgeZeroToTwentyFourMonths,
    imcForAgeTwoToFiveYears,
    imcForAgeZeroToFiveYears,
  }

  return lines[defaultLines][type][sex]
}

export const getDefaultUnit = (defaultLines: EAnthropometryChartDefaultLines) => {
  if (defaultLines === EAnthropometryChartDefaultLines.heightForAge) {
    return {
      unit: 'cm',
      title: 'Estatura para idade (5 a 19 anos)',
    }
  }
  if (defaultLines === EAnthropometryChartDefaultLines.imcForAge) {
    return {
      unit: 'IMC',
      title: 'IMC para idade (5 a 19 anos)',
    }
  }
  if (defaultLines === EAnthropometryChartDefaultLines.weightForAgeFive) {
    return {
      unit: 'kg',
      title: 'Peso para idade (0 a 5 anos)',
    }
  }
  if (defaultLines === EAnthropometryChartDefaultLines.weightForAgeTen) {
    return {
      unit: 'kg',
      title: 'Peso para idade (5 a 10 anos)',
    }
  }
  if (defaultLines === EAnthropometryChartDefaultLines.imcForAgeZeroToTwentyFourMonths) {
    return {
      unit: 'IMC',
      title: 'IMC para idade (0 a 24 meses)',
    }
  }
  if (defaultLines === EAnthropometryChartDefaultLines.imcForAgeZeroToFiveYears) {
    return {
      unit: 'IMC',
      title: 'IMC para idade (0 a 5 anos)',
    }
  }
  if (defaultLines === EAnthropometryChartDefaultLines.heightForAgeZeroToTwentyFourMonths) {
    return {
      unit: 'cm',
      title: 'Estatura para idade (0 a 24 meses)',
    }
  }
  if (defaultLines === EAnthropometryChartDefaultLines.heightForAgeTwoToFiveYears) {
    return {
      unit: 'cm',
      title: 'Estatura para idade (2 a 5 anos)',
    }
  }
  if (defaultLines === EAnthropometryChartDefaultLines.heightForAgeZeroToFiveYears) {
    return {
      unit: 'cm',
      title: 'Estatura para idade (0 a 5 anos)',
    }
  }
  if (defaultLines === EAnthropometryChartDefaultLines.imcForAgeTwoToFiveYears) {
    return {
      unit: 'IMC',
      title: 'IMC para idade (2 a 5 anos)',
    }
  }
  return {
    unit: 'cm',
    title: '',
  }
}

export const roundedMonth = (month: number) => {
  let value = month

  while (value > 12) {
    value -= 12
  }
  return Math.round(value)
}

export const monthsReference = [
  0,
  12,
  24,
  36,
  48,
  60,
  72,
  84,
  96,
  108,
  120,
  132,
  144,
  156,
  168,
  180,
  192,
  204,
  216,
  228,
  240,
]

export const lineZscore = [
  {
    dataKey: '3',
    stroke: '#2D3139',
  },
  {
    dataKey: '2',
    stroke: '#D93B2A',
  },
  {
    dataKey: '1',
    stroke: '#F5D06C',
  },
  {
    dataKey: '0',
    stroke: '#369B58',
  },
  {
    dataKey: '-1',
    stroke: '#F5D06C',
  },
  {
    dataKey: '-2',
    stroke: '#D93B2A',
  },
  {
    dataKey: '-3',
    stroke: '#2D3139',
  },
  {
    dataKey: 'patient',
    stroke: '#000000',
  },
]

export const linePercentil = [
  {
    dataKey: 'p97',
    stroke: '#D93B2A',
  },
  {
    dataKey: 'p85',
    stroke: '#F5D06C',
  },
  {
    dataKey: 'p50',
    stroke: '#369B58',
  },
  {
    dataKey: 'p15',
    stroke: '#F5D06C',
  },
  {
    dataKey: 'p3',
    stroke: '#D93B2A',
  },
  {
    dataKey: 'patient',
    stroke: '#000000',
  },
]

export const formatAgeTooltip = (value: number): string => {
  let content = ''

  if (value === 0) return 'Nasc.'

  if (monthsToYears(value) === 0) {
    return `${value} ${value === 1 ? 'mês' : 'meses'}`
  }

  if (monthsToYears(value) > 0) {
    content += `${monthsToYears(value)} ${monthsToYears(value) > 1 ? 'anos' : 'ano'}`
  }

  if (roundedMonth(value) > 0 && roundedMonth(value) < 12) {
    content += ` e ${roundedMonth(value)} ${roundedMonth(value) > 1 ? 'meses' : 'mês'}`
  }

  return content
}

export enum EFormatName {
  p97 = 'p97',
  p85 = 'p85',
  p50 = 'p50',
  p15 = 'p15',
  p3 = 'p3',
  patient = 'patient',
}

export const formatName = (value: EFormatName): string => {
  const labelPercentil = {
    p97: '97th',
    p85: '85th',
    p50: '50th',
    p15: '15th',
    p3: '3r',
    patient: 'Paciente',
  }

  return labelPercentil[value] || value
}
