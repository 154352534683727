import React, { useCallback, useEffect, useState } from 'react'
import { TableFixed, ITableFixedProps } from '../../molecules/TableFixed/TableFixed'
import { EditableCallbackValue, IData } from '../../molecules/TableFixed/typing'
import FileSearching from '../ExamResultTable/FileSearching'
import { formatImc } from './functions'
import { Container } from './styles'

interface IEvolutiveTableProps
  extends Omit<
    ITableFixedProps,
    'onSearchChange' | 'onSearchBlur' | 'onSearchEnter' | 'isSearchable'
  > {}
const EvolutiveTable: React.FC<IEvolutiveTableProps> = ({
  data,
  onBlurEditableCallback,
  isEmpty,
  ...props
}) => {
  const [tableData, setTableData] = useState<IData>(data)

  const calculateImc = useCallback((dataTable: IData) => {
    let newData: IData = dataTable
    newData = {
      ...newData,
      scrollableColumns: newData?.scrollableColumns.map(column => {
        const { rows } = column
        const [weight, stature] = [Number(rows[0]?.value), Number(rows[1]?.value)]

        if (!weight || !stature) {
          column.rows[2].value = '-'
          return column
        }

        const result = Number((weight / (stature / 100) ** 2).toFixed(2))
        column.rows[2].value = formatImc(result)
        return column
      }),
    }
    setTableData(newData)
  }, [])

  const handleEditCell = ({ columnId, header, row, value }: EditableCallbackValue) => {
    let hasChanged = false
    const newData: IData = {
      ...tableData,
      scrollableColumns: tableData.scrollableColumns.map(column => {
        if (column.id === columnId) {
          const editedRowIndex = column.rows.findIndex(({ id }) => id === row.id)

          if (column.rows[editedRowIndex].value.toString() !== value.toString()) hasChanged = true

          column.rows[editedRowIndex].value = value

          return {
            ...column,
            rows: column.rows,
          }
        }

        return column
      }),
    }

    if (hasChanged) {
      calculateImc(newData)
      onBlurEditableCallback?.({ columnId, header, row, value })
    }
  }

  useEffect(() => {
    setTableData(prevState => ({ ...prevState, ...data }))
  }, [data])

  return (
    <Container hasData={!!tableData?.scrollableColumns?.length}>
      <TableFixed
        data={tableData}
        title="Dados evolutivos"
        subTitle="Comparativo medições anteriores"
        isEmpty={isEmpty ?? (!props.isLoading && !tableData?.scrollableColumns?.length)}
        onBlurEditableCallback={handleEditCell}
        emptyMessage={{
          icon: <FileSearching size={100} color="#DEE1E8" />,
          title: 'Nenhum dado evolutivo registrado',
          description: 'Não há dados para serem exibidos',
        }}
        {...props}
      />
    </Container>
  )
}

export default EvolutiveTable
