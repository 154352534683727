import React from 'react'
import TableFixed, { ITableFixedProps } from '../../molecules/TableFixed'
import { ILoadPages, IData as ITableFixedData } from '../../molecules/TableFixed/typing'
import FileSearching from './FileSearching'
import { dataDTO } from './functions'
import { IData, IDeleteColumnTable, IEditable, IResult, ISimpleResource } from './typing'

type ITableFixed = Omit<
  ITableFixedProps,
  | 'data'
  | 'handleAdd'
  | 'onBlurEditableCallback'
  | 'onDeleteEditableCallback'
  | 'onHandleDeleteColumnCallback'
>
export interface IExamResultTable extends ITableFixed, IEditable, IDeleteColumnTable, ILoadPages {
  data?: IData
  handleAddExam?(): void
  description?: {
    addExamButtonLabel?: string
  }
}

const ExamResultTable: React.FC<IExamResultTable> = ({
  data,
  handleAddExam,
  description,
  onBlurEditableCallback,
  onDeleteEditableCallback,
  onHandleDeleteColumnCallback,
  canLoadPage,
  isLoadingPage,
  handleLoadPageCallback,
  ...props
}) => {
  return (
    <TableFixed
      data={data?.examResults ? dataDTO(data) : ((data as unknown) as ITableFixedData)}
      addButtonLabel={description?.addExamButtonLabel}
      {...{ canLoadPage, isLoadingPage, handleLoadPageCallback }}
      handleAdd={handleAddExam}
      isLoading
      emptyMessage={{
        icon: <FileSearching size={100} color="#DEE1E8" />,
        title: 'Nenhum exame registrado',
        description: 'Não há exames para serem exibidos',
      }}
      onBlurEditableCallback={({ header, row, value }) => {
        const laboratory = { id: header.id, description: header.description } as ISimpleResource
        onBlurEditableCallback?.({ laboratory, result: (row as unknown) as IResult, value })
      }}
      onDeleteEditableCallback={({ header, row, value }) => {
        const laboratory = { id: header.id, description: header.description } as ISimpleResource
        onDeleteEditableCallback?.({ laboratory, result: (row as unknown) as IResult, value })
      }}
      onHandleDeleteColumnCallback={({ header }) => {
        const laboratory = { id: header?.id, description: header?.description } as ISimpleResource
        onHandleDeleteColumnCallback?.({ value: header?.title as string, laboratory })
      }}
      {...props}
    />
  )
}

export default ExamResultTable
