import React, { useRef, useState } from 'react'
import { Icon, MenuDropdown } from 'components/atoms'
import { useMediaQuery } from '@mui/material'
import { muiTheme, theme } from '@cockpit/levi-ui/theme/theme'
import { format } from 'date-fns'
import Field from './Field'
import { IHeader, IMenuOption, IRow, IRowOptions } from '../typing'
import {
  ButtonIconDelete,
  ButtonModal,
  ContainerField,
  ContainerTitle,
  ContainerTitleLeft,
  DotButton,
  ModalEdit,
  NameRow,
  ScrollableRowContainer,
  SubTitleModal,
  TitleModal,
  TitleRegistrationManual,
} from '../styles'
import { useTableFixed } from '../hook'

export interface IScrollableRowProps {
  header: IHeader
  row: IRow | null
  rowIndex: number
  columnId: number
  isMobile?: boolean
}

const ScrollableRow: React.FC<IScrollableRowProps> = ({
  header,
  row,
  rowIndex,
  columnId,
  isMobile,
}) => {
  const {
    data: { rowsOptions },
    onBlurEditableCallback,
    onDeleteEditableCallback,
  } = useTableFixed()

  const { formatter, ...rowsOptionsDefault }: IRowOptions = {
    mask: rowsOptions?.[rowIndex]?.mask ?? row?.mask,
    editable: rowsOptions?.[rowIndex]?.editable ?? row?.editable ?? true,
    deletable: rowsOptions?.[rowIndex]?.deletable ?? row?.deletable ?? true,
    disabled: rowsOptions?.[rowIndex]?.disabled ?? row?.disabled ?? false,
    showDotsMenu: rowsOptions?.[rowIndex]?.showDotsMenu ?? row?.showDotsMenu ?? true,
    editedEffect: rowsOptions?.[rowIndex]?.editedEffect ?? row?.editedEffect ?? false,
    numeric: rowsOptions?.[rowIndex]?.numeric ?? row?.numeric ?? false,
    formatter: rowsOptions?.[rowIndex]?.formatter ?? row?.formatter,
  }

  const inputRef = useRef<HTMLInputElement>(null)
  const iconRef = useRef<HTMLButtonElement>(null)

  const [menuHandler, setMenuHandler] = useState<{
    open: boolean
    action: 'edit' | 'delete' | 'none'
  }>({
    open: false,
    action: 'none',
  })

  const [value, setValue] = React.useState(row?.value ?? '-')
  const [openModal, setOpenModal] = useState(false)

  const matchesMobile = useMediaQuery(muiTheme.breakpoints.down('sm'))
  const [isEditable, isDeletable, isEdited, isNumeric] = [
    !(!onBlurEditableCallback || !rowsOptionsDefault?.editable || rowsOptionsDefault?.disabled),
    !(
      !onDeleteEditableCallback ||
      !rowsOptionsDefault?.deletable ||
      !rowsOptionsDefault?.editable ||
      rowsOptionsDefault?.disabled
    ),
    rowsOptionsDefault?.editedEffect && row?.previousValue ? row?.previousValue !== value : false,
    rowsOptionsDefault?.numeric && value !== '-',
  ]
  const showUnitOfMesure =
    value &&
    row?.unitOfMeasure &&
    row?.unitOfMeasure?.description &&
    row.unitOfMeasure.description !== 'N/A'

  const menuOptions = [
    {
      text: 'Editar',
      startIcon: <Icon icon="Pencil" size={16} />,
      onClick: () => setMenuHandler({ action: 'edit', open: false }),
      disabled: false,
    },
    {
      text: 'Excluir',
      startIcon: <Icon icon="Trash" size={16} />,
      onClick: () => setMenuHandler({ action: 'delete', open: false }),
      disabled: false,
    },
  ].reduce((prev, option) => {
    const { text } = option
    if (text === 'Editar' && !isEditable) return prev
    if (text === 'Excluir' && !isDeletable) return prev
    return [...prev, option]
  }, [] as Array<IMenuOption>)

  const showDotsMenu = !!menuOptions.length && rowsOptionsDefault?.showDotsMenu

  const handleToggleMenu = () => setMenuHandler({ ...menuHandler, open: !menuHandler.open })

  const handleOnChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = formatter ? formatter(event.target.value.replace('-', '')) : event.target.value
      setValue(value as React.SetStateAction<string>)
    },
    [formatter],
  )

  const handleOnBlur = React.useCallback(() => {
    onBlurEditableCallback?.({ columnId, row: row as IRow, value, header })
  }, [header, onBlurEditableCallback, row, value, columnId])

  React.useEffect(() => {
    switch (menuHandler.action) {
      case 'edit':
        inputRef.current?.focus()
        break
      case 'delete':
        onDeleteEditableCallback?.({ columnId, row: row as IRow, value, header })
        break
      default:
        break
    }
    if (menuHandler.action !== 'none') setMenuHandler({ action: 'none', open: menuHandler.open })
  }, [header, menuHandler, onDeleteEditableCallback, row, value, columnId])

  const handleOnKeyDown = React.useCallback((event: React.KeyboardEvent<Element>) => {
    if (event.key === 'Enter') {
      const inputs = Array.from(document.querySelectorAll('input'))
      const currentInputIndex = inputs.indexOf(event.currentTarget as HTMLInputElement)
      const nextInputId = inputs[currentInputIndex + 1].id

      const nextInput = document.getElementById(nextInputId)
      if (nextInput) {
        nextInput.focus()
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        nextInput.select()
      }
    }
  }, [])

  React.useEffect(() => {
    if (row?.value) {
      setValue(row.value)
    }
  }, [row?.value])

  const formatDate = (): string => {
    if (typeof header.title === 'string') {
      const [day, month, year] = header.title.split('/')

      const parsedDate = `${year}-${month}-${day}`

      if (!Date.parse(parsedDate)) return ''

      return ` do dia ${format(new Date(parsedDate), 'dd LLL')}`
    }

    return ''
  }

  if (isMobile || matchesMobile) {
    return (
      <>
        <ModalEdit
          title=""
          onClose={() => setOpenModal(!openModal)}
          open={openModal}
          fullScreen
          enabledSlide
        >
          <ContainerTitle>
            <ContainerTitleLeft>
              <TitleModal>Editar resultado</TitleModal>
              {row?.unitOfMeasure?.description && (
                <SubTitleModal>
                  <NameRow>{row?.description}</NameRow>
                  {formatDate()}
                </SubTitleModal>
              )}
            </ContainerTitleLeft>
            {header.info?.description && (
              <TitleRegistrationManual>{header.info?.description}</TitleRegistrationManual>
            )}
          </ContainerTitle>
          <ContainerField>
            <Field
              ref={inputRef}
              id={`table-fixed-text-field-index-${rowIndex}-columnIndex-${columnId}`}
              data-testid="table-fixed-item-text-field"
              value={value}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              max={row?.maxValue ?? undefined}
              min={row?.minValue ?? undefined}
              onKeyDown={handleOnKeyDown}
              iconOptions={
                showUnitOfMesure
                  ? {
                      adornment: row?.unitOfMeasure?.description,
                      position: 'end',
                    }
                  : undefined
              }
              mask={value === '-' ? undefined : rowsOptionsDefault?.mask}
              isNumeric={isNumeric}
              disabled={rowsOptionsDefault?.disabled || !rowsOptionsDefault?.editable}
            />

            {isDeletable && (
              <ButtonIconDelete
                data-testid="button-delete-icon"
                onClick={() => {
                  setOpenModal(!openModal)
                  onDeleteEditableCallback?.({ columnId, row: row as IRow, value, header })
                }}
              >
                <Icon size={16} icon="Trash" color={theme.colors.neutral[80]} />
              </ButtonIconDelete>
            )}
          </ContainerField>
        </ModalEdit>

        <ButtonModal onClick={() => setOpenModal(!openModal)} data-testid="table-fixed-input-modal">
          <Field
            ref={inputRef}
            id={`table-fixed-text-field-index-${rowIndex}-columnIndex-${columnId}`}
            data-testid="table-fixed-item-text-field"
            value={value}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            isMobile={isMobile || matchesMobile}
            isEdited={isEdited}
            isNumeric={isNumeric}
            max={row?.maxValue ?? undefined}
            min={row?.minValue ?? undefined}
            onKeyDown={handleOnKeyDown}
            iconOptions={
              showUnitOfMesure
                ? {
                    adornment: row?.unitOfMeasure?.description,
                    position: 'end',
                  }
                : undefined
            }
            mask={value === '-' ? undefined : rowsOptionsDefault?.mask}
            disabled
          />
        </ButtonModal>
      </>
    )
  }

  return (
    <ScrollableRowContainer
      isEditable={isEditable}
      data-testid="table-fixed-row-container"
      isMenuOpened={menuHandler.open}
    >
      <Field
        ref={inputRef}
        id={`table-fixed-index-${rowIndex}-columnIndex-${columnId}`}
        data-testid="table-fixed-item-text-field"
        value={value}
        isEdited={isEdited}
        isNumeric={isNumeric}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        max={row?.maxValue ?? undefined}
        min={row?.minValue ?? undefined}
        onKeyDown={handleOnKeyDown}
        iconOptions={
          showUnitOfMesure
            ? {
                adornment: row?.unitOfMeasure?.description,
                position: 'end',
              }
            : undefined
        }
        mask={value === '-' ? undefined : rowsOptionsDefault?.mask}
        tooltipOnEllipsis
        disabled={!isEditable || rowsOptionsDefault?.disabled || !rowsOptionsDefault?.editable}
      />
      {showDotsMenu && (
        <>
          <DotButton
            onClick={handleToggleMenu}
            ref={iconRef}
            disableRipple
            data-testid="table-fixed-item-dot-button"
            sx={{
              color: 'info.main',
              ':hover': {
                color: 'primary.main',
              },
              transform: 'rotate(90deg)',
            }}
          >
            <Icon size={16} icon="Ellipsis" />
          </DotButton>
          <MenuDropdown
            options={menuOptions}
            anchorEl={iconRef?.current}
            open={menuHandler.open}
            onClose={handleToggleMenu}
          />
        </>
      )}
    </ScrollableRowContainer>
  )
}

export default ScrollableRow
