import React, { forwardRef } from 'react'
import { IMaskedField } from '../../MaskedField'
import Tooltip from '../../../atoms/Tooltip'
import { MaskedFieldTable, TextFieldTable } from '../styles'

type FieldProps = Partial<IMaskedField> & {
  isEdited?: boolean
  isMobile?: boolean
  isNumeric?: boolean
}
const Field = forwardRef<HTMLInputElement, FieldProps>(
  ({ mask, isMobile, isEdited, isNumeric, ...props }: FieldProps, ref) => {
    const renderField = () => {
      if (mask) {
        const hasFloatMask =
          !!mask?.split('.')?.length &&
          props.value &&
          props.value.toString().length - 1 === mask.split('.')[0].length

        return (
          <MaskedFieldTable
            mask={hasFloatMask ? mask.split('.')[0].toString() : mask}
            className={isEdited ? 'table-fixed-field-edited' : undefined}
            {...props}
          />
        )
      }

      return (
        <TextFieldTable
          ref={ref}
          className={isEdited ? 'table-fixed-field-edited' : undefined}
          type={isNumeric ? 'number' : undefined}
          {...props}
        />
      )
    }

    if (isEdited) {
      return (
        <Tooltip
          open={isMobile ? false : undefined}
          title="Valores editados"
          element={<div>{renderField()}</div>}
          placement="bottom"
          componentsProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -16],
                  },
                },
              ],
            },
          }}
        />
      )
    }

    return renderField()
  },
)

export default Field
