import styled from '@emotion/styled/macro'
import { theme } from '@cockpit/levi-ui/theme'
import { IconButton, LinearProgress, Popover } from '@mui/material'
import { getColors } from 'helpers'
import TextField from '../TextField/TextField'
import MaskedField from '../MaskedField/MaskedField'
import Button from '../../atoms/Button'
import Modal from '../Modal/Modal'

const Container = styled.div<{ hasFixedColumn: boolean }>`
  background-color: ${theme.colors.neutral[10]};
  padding: 24px;
  display: grid;
  grid-template-columns: 287px 1fr;
  grid-auto-rows: auto;
  grid-template-areas: ${({ hasFixedColumn }) =>
    hasFixedColumn
      ? `
    'table-header table-header'
    'date-filter date-filter'
    'loader loader'
    'headers data'
    `
      : `
    'table-header table-header'
    'date-filter date-filter'
    'loader loader'
    'data data'
    `};
  grid-auto-flow: column;
  border-radius: 4px;

  @media (max-width: 600px) {
    background-color: transparent;
    padding: 0;
  }

  @media (max-width: 500px) {
    display: grid;
    grid-template-columns: 159px 1fr;
    grid-template-areas: ${({ hasFixedColumn }) =>
      hasFixedColumn
        ? `
    'table-header table-header'
    'date-filter date-filter'
    'loader loader'
    'headers data'
    `
        : `
    'table-header table-header'
    'date-filter date-filter'
    'loader loader'
    'data data'
    `};
  }
`
const BottomContainer = styled.div`
  grid-column: 1 / -1;
  grid-row: -1;
  display: grid;
  place-items: center;
  padding-top: 10px;
  button {
    width: 100%;
  }
`

const ButtonIcon = styled(Button)`
  width: 28px;
`

const EmptyContainer = styled.div`
  grid-column: 1 / -1;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacings[1]};
  svg {
    width: 170px;
  }
  h3,
  p {
    margin: ${theme.spacings[1]} 0px;
    text-align: left;
    letter-spacing: 0px;
  }
  h3 {
    font-weight: 600;
    font-family: Inter;
    color: ${theme.colors.neutral[80]};
  }
  p {
    font: 15px/17px Inter;
    color: ${theme.colors.neutral[50]};
    font-weight: 500;
  }
`

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  grid-column: 1 / -1;
`

// Table header
const Search = styled(TextField)`
  margin-left: ${theme.spacings[2]};
`
const Loader = styled(LinearProgress)`
  grid-area: loader;
`

const AddButton = styled(Button)`
  min-width: 20%;
  width: fit-content;
  font-size: 14px;
  font-weight: normal;
  justify-self: flex-start;
  @media (max-width: 500px) {
    grid-area: addButton;
    width: 100%;
  }
`
const PaginationContainer = styled.div<{ hasTitle?: boolean }>`
  background-color: ${theme.colors.neutral[10]};
  grid-area: pagination;
  display: flex;
  justify-content: end;
  align-items: ${({ hasTitle }) => (hasTitle ? 'flex-start' : 'center')};
  gap: ${theme.spacings[1]};
  height: 100%;

  button {
    min-width: 10px;
  }
`
const TableHeaderContainer = styled.div<{
  isFilterable?: boolean
  hasTitle?: boolean
  hasSubTitle: boolean
}>`
  height: 56px;
  grid-area: table-header;
  display: flex;
  margin-bottom: ${({ hasSubTitle, hasTitle }) => (!hasSubTitle || hasTitle) && theme.spacings[5]};
  align-items: center;
  gap: ${theme.spacings[3]};
  justify-content: ${({ hasTitle }) => (hasTitle ? 'space-between' : 'flex-end')};

  ${Search} {
    max-width: 50%;
    justify-self: flex-start;
  }
  @media (max-width: 500px) {
    justify-content: flex-start;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    ${Search} {
      max-width: 100%;
      justify-self: flex-start;
      margin-left: 0;
    }
  }
`
const SubTitle = styled.div<{ hasTitle?: boolean }>`
  font-size: ${theme.font.size[2]};
  ${({ hasTitle }) =>
    !hasTitle &&
    `
  padding-top: ${theme.spacings[3]};
  padding-bottom: ${theme.spacings[3]};
  font-weight: ${theme.font.weight[6]};
  `}
  color: ${theme.colors.neutral[60]};
`
const Title = styled.h3`
  font: normal normal 600 ${theme.font.size[5]} / ${theme.font.size[4]} Inter;
  color: ${theme.colors.neutral[80]};
  margin: ${theme.spacings[2]} 0;
`
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: baseline;
  width: 100%;
`

// Filter
const ContainerData = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: date-filter;
  margin-bottom: ${theme.spacings[3]};
`
const ContainerSelectedDates = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-self: flex-start;
  color: ${theme.colors.neutral[60]};
  font-weight: ${theme.font.weight[6]};
  font-size: ${theme.font.size[2]};

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: ${theme.spacings[1]};
  }
`
const TitleSelectedDates = styled.p`
  color: #257ce5;
  font-weight: ${theme.font.weight[5]};
  font-size: ${theme.font.size[2]};
  align-self: center;
`
const ButtonClearFilter = styled(Button)`
  padding: 0;
  height: 30px;
  width: 20px;
  min-width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const SelectedDates = styled.div`
  background: rgba(235, 243, 255, 1);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  width: auto;
  height: 30px;
  padding: 0 9px;
  margin-left: 12px;
  svg {
    margin-left: 8px;
    color: #1c7cf9;
    cursor: pointer;
  }
`

// Fixed column
const FixedRowTitle = styled.h1`
  font: 14px/18px Inter;
  font-weight: 600;
  text-align: left;
  letter-spacing: 0px;
  color: ${theme.colors.neutral[80]};

  @media (max-width: 500px) {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
const FixedRowDescription = styled.div`
  font: 12px/15px Inter;
  font-weight: 500;
  font-style: italic;
`
const FixedRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
`
const FixedRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 65px;
  padding: 0 ${theme.spacings[4]};
`
const FixedTitle = styled.div<{ isLoading?: boolean }>`
  padding-left: ${theme.spacings[4]};
  display: flex;
  align-items: center;
  font: 14px/16px Inter;
  letter-spacing: 0px;
  color: ${theme.colors.neutral[80]};
  opacity: 1;
  text-align: left;
  height: 56px;
  font-weight: 600;
  background: ${theme.colors.neutral[20]};
  border-radius: ${({ isLoading }) => (!isLoading ? '4px' : '0')} 0 0 0;
`
const FixedContainer = styled.div`
  grid-area: headers;
  display: flex;
  flex-direction: column;
  border-right: ${theme.colors.neutral[30]} 1px solid;

  ${FixedRowContainer}:nth-child(even) {
    background-color: ${theme.colors.neutral[10]};
  }

  ${FixedRowContainer}:nth-child(odd) {
    background-color: ${theme.colors.neutral[20]};
  }
`

// Scrollable Row/Cell
const TextFieldTable = styled(TextField)<{ disabled?: boolean }>`
  height: 65px !important;
  border: 1px solid transparent;
  background-color: transparent !important;
  text-align: center;
  color: ${theme.colors.neutral[60]} !important;

  ${({ disabled }) =>
    disabled &&
    `
      input {
        text-fill-color: ${theme.colors.neutral[60]} !important;
      }
      :hover {
        border: 1px solid white !important;
      }
  `}

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button, 
  input[type='number'] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield;
  }
`
const MaskedFieldTable = styled(MaskedField)<{ disabled?: boolean }>`
  height: 65px !important;
  border: 1px solid transparent;
  background-color: transparent !important;
  text-align: center;
  color: ${theme.colors.neutral[60]} !important;

  ${({ disabled }) =>
    disabled &&
    `
      input {
        text-fill-color: ${theme.colors.neutral[60]} !important;
      }
      :hover {
        border: 1px solid white !important;
      }
  `}
`
const DotButton = styled(IconButton)`
  padding: 0;
  span {
    margin-right: 0;
    margin-left: 0;
  }
`
const ButtonIconDelete = styled(Button)`
  width: 32px;
  height: 32px;
  border-radius: ${theme.spacings[2]};
  background-color: ${theme.colors.neutral[20]};
  min-width: 0;
  align-self: flex-end;
  margin-right: 22px;
`

const ButtonModal = styled.button`
  border: 0;
  padding: 0;
  margin: 0;
  display: flex;
  ${TextFieldTable}, input {
    text-align: center;
    color: ${getColors('neutral', 80)};
    font-weight: ${theme.font.weight[6]};
  }
  ${MaskedFieldTable}, input {
    text-align: center;
    color: ${getColors('neutral', 80)};
    font-weight: ${theme.font.weight[6]};
  }
`

const ScrollableRowContainer = styled.div<{ isEditable?: boolean; isMenuOpened?: boolean }>`
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${theme.font.size[2]};
  color: ${({ isEditable }) => (isEditable ? getColors('neutral', 60) : getColors('neutral', 80))};
  font-weight: ${theme.font.weight[5]};
  &:not(:hover) {
    ${DotButton} {
      ${({ isMenuOpened }) => (isMenuOpened ? '' : 'display: none;')}
    }
  }
  ${TextFieldTable}, input {
    text-align: center;
    color: ${getColors('neutral', 80)};
    font-weight: ${theme.font.weight[6]};
  }
  ${MaskedFieldTable}, input {
    text-align: center;
    color: ${getColors('neutral', 80)};
    font-weight: ${theme.font.weight[6]};
  }
`
const ScrollableRowContent = styled.div<{ hasUnitOfMesure?: boolean }>`
  flex: 1;
  text-align: ${({ hasUnitOfMesure }) => (hasUnitOfMesure ? 'right' : 'center')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  > button {
    color: ${theme.colors.neutral[60]} !important;
  }
`

const TitleModal = styled.p`
  width: 100%;
  color: ${theme.colors.neutral[80]};
  font-weight: ${theme.font.weight[5]};
  font-size: ${theme.font.size[3]};
  align-self: center;
  margin: 0 0 7px 0;
`

const SubTitleModal = styled.p`
  display: flex;
  color: ${theme.colors.neutral[60]};
  font-weight: ${theme.font.weight[5]};
  font-size: ${theme.font.size[2]};
  align-self: flex-start;
  flex-direction: row;
  margin: 0 0 8px 0;
`

const ModalEdit = styled(Modal)`
  && .MuiDialogContent-root {
    padding: 0;
  }
  ${TextFieldTable}, input {
    text-align: flex-start;
    color: ${getColors('neutral', 80)};
    font-weight: ${theme.font.weight[6]};
    height: 35px !important;
    width: 50%;
  }

  ${TextFieldTable} {
    margin-left: 22px;
  }
  ${MaskedFieldTable}, input {
    height: 35px !important;
    width: 50%;
    text-align: flex-start;
    color: ${getColors('neutral', 80)};
    font-weight: ${theme.font.weight[6]};
    margin-left: 22px;
  }
`

const TitleRegistrationManual = styled.p`
  color: #257ce5;
  display: flex;
  font-weight: ${theme.font.weight[5]};
  font-size: ${theme.font.size[2]};
  align-self: flex-end;
  padding: 0;
  margin: 0 0 7px 0;
`
const ContainerTitle = styled.div`
  display: flex;
  width: auto;
  padding: 24px 22px 0 22px;
  justify-content: space-between;
  align-items: flex-end;
  ${MaskedFieldTable}, input {
    height: 30px;
  }
`

const ContainerTitleHeader = styled.div`
  display: flex;
  width: auto;
  padding: 25px 22px 0 22px;
  justify-content: space-between;
  align-items: flex-end;
  ${MaskedFieldTable}, input {
    height: 30px;
  }
  height: 88px;
`

const ContainerField = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 25px;
`

const NameRow = styled.div`
  width: 40%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const TitleModalHeader = styled.p`
  width: 100%;
  color: ${theme.colors.neutral[80]};
  font-weight: ${theme.font.weight[5]};
  font-size: ${theme.font.size[3]};
  align-self: center;
  margin: 0 0 7px 0;
`

const ContainerTitleLeft = styled.div`
  display: flex;
  height: 100%;
  width: 50%;
  flex-direction: column;
  justify-content: space-between;

  button {
    margin-right: 0;
  }
`

// Scrollable column
const ScrollableColumn = styled.div<{ hasCustomTitle?: boolean }>`
  display: flex;
  flex-direction: column;
  border-right: ${theme.colors.neutral[30]} 1px solid;
  min-width: 140px;
  max-width: 144px;
  width: ${({ hasCustomTitle }) => (hasCustomTitle ? '160px' : 'auto')};

  ${ScrollableRowContainer}:nth-child(even) {
    background-color: ${theme.colors.neutral[10]};
  }

  ${ScrollableRowContainer}:nth-child(odd) {
    background-color: ${theme.colors.neutral[20]};
  }

  ${ButtonModal}:nth-child(even) {
    background-color: ${theme.colors.neutral[10]};
  }

  ${ButtonModal}:nth-child(odd) {
    background-color: ${theme.colors.neutral[20]};
  }
`
const ScrollableContainer = styled.div`
  grid-area: data;
  display: grid;
  grid-template: auto / repeat(auto-fit, 1fr);
  grid-auto-flow: column;
  justify-content: start;
  overflow-y: hidden;
  overflow-x: overlay;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;

  ${ScrollableColumn}:not(:last-child) {
    scroll-snap-align: start;
  }

  ${ScrollableColumn}:last-child {
    border-right: ${theme.colors.neutral[30]} 1px solid;
    scroll-snap-align: end;
  }

  &::-webkit-scrollbar {
    max-height: 10px;
    transition: max-height 2s;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${theme.colors.neutral[40]};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.neutral[50]};
    border-radius: ${theme.spacings[2]};
  }

  &::-webkit-scrollbar-track-piece {
    background-color: ${theme.colors.neutral[20]};
  }
`
const ScrollableHeader = styled.div<{
  lastIndex?: boolean
  isLoading?: boolean
  isMenuOpened?: boolean
}>`
  height: 56px;
  color: ${theme.colors.neutral[60]};
  text-align: center;
  background: ${theme.colors.neutral[20]};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacings[1]};
  border-radius: ${({ lastIndex, isLoading }) =>
    !lastIndex ? 'unset' : `0 ${!isLoading ? '4px' : '0'} 0 0`};

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  > div > div {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    > input {
      width: 80px;
      padding: center;
      text-align: center;
    }
  }
  &:not(:hover) {
    ${DotButton} {
      ${({ isMenuOpened }) => (isMenuOpened ? '' : 'display: none;')}
    }
  }
`
const ScrollableHeaderTitleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font: 14px/15px Inter;
`
const ScrollableHeaderTitle = styled.strong`
  font-weight: 600;
`

const ScrollableHeaderDescription = styled.div`
  font: 12px/15px Inter;
  font-weight: 500;
  font-style: italic;
`
const TextTooltip = styled.span`
  display: flex;
  text-align: center;
  flex-direction: column;
  > span {
    padding-bottom: 5px;
  }
`
const InfoLabel = styled.span`
  background: ${theme.colors.neutral[60]};
  border-radius: 4px;
  display: flex;
  color: ${theme.colors.neutral[10]};
  justify-content: center;
  align-items: center;
  font-size: 10px;
  align-self: center;
  width: 16px;
  height: 14px;
  margin-right: ${theme.spacings[2]};
  cursor: default;

  @media (max-width: 500px) {
    margin-top: 4px;
    margin-bottom: 4px !important;
  }
`
const ScrollableHeaderContent = styled.div<{ isEditable?: boolean; isMenuOpened?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: transparent;
`
const ModalMobileTitle = styled.h1`
  font: 14px/18px Inter;
  font-weight: 600;
  text-align: left;
  margin: 14px 0;
  letter-spacing: 0px;
  color: ${theme.colors.neutral[80]};
`
const ModalMobileContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  input {
    text-align: center;
  }
`
const MobileButton = styled(Button)`
  display: flex;
  width: 100%;
  max-height: 56px;
  height: 56px;
  padding: 0;
  color: ${theme.colors.neutral[60]};
  span {
    margin-bottom: ${theme.spacings[3]};
  }
`

// ModalFilter
const ButtonFilter = styled(Button)`
  min-width: 112px;
  width: fit-content;
  font-size: 14px;
  font-weight: normal;
  justify-self: flex-start;
  gap: 7px;
  @media (max-width: 500px) {
    min-width: 40px;
    min-height: 40px;
    padding: 5px 5px;
  }
`

const ContentHeaders = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 8px;
`

const ContainerDates = styled.div`
  display: flex;
  flex-direction: column;
  padding: 29px 24px 24px;
  width: 448px;
  border-radius: 8px;
  margin: ${theme.spacings[4]} 0;

  div {
    display: flex;
    gap: ${theme.spacings[3]};
  }
`
const ContentDates = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
  }
`
const ContentFilterHeader = styled.div`
  display: flex;
  gap: ${theme.spacings[3]};
`
const ContainerFilterModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`
const DateLabel = styled.p`
  font-size: 14px;
  color: ${theme.colors.neutral[50]};
  @media (max-width: 600px) {
    margin-bottom: ${theme.spacings[1]} !important;
  }
`
const FooterDates = styled.div`
  display: flex;
  border: 1px solid ${theme.colors.neutral[30]};
  border-width: 1px 0 0 0;
  padding-top: 16px;
  margin-top: 20px;
  gap: 16px;
  justify-content: flex-end;

  @media (max-width: 600px) {
    flex-direction: column;
    div {
      display: flex;
      justify-content: space-evenly;
      width: 100%;
      gap: 32px;
      button {
        width: 100%;
      }
    }
  }
`
const PopoverFilter = styled(Popover)`
  display: flex;
  flex-direction: column;
`

// Modal
const ModalStyled = styled(Modal)`
  z-index: 99999 !important;
`

export {
  Container,
  EmptyContainer,
  ButtonIcon,
  ModalStyled,
  LoadingContainer,
  BottomContainer,
  // Table header
  Search,
  Loader,
  ContentHeaders,
  ButtonIconDelete,
  AddButton,
  PaginationContainer,
  TableHeaderContainer,
  SubTitle,
  TitleContainer,
  Title,
  // Fixed column
  FixedContainer,
  FixedTitle,
  FixedRowContainer,
  FixedRow,
  FixedRowTitle,
  FixedRowDescription,
  // Scrollable columns
  ScrollableContainer,
  ContainerTitleHeader,
  ScrollableColumn,
  ScrollableHeader,
  ScrollableHeaderTitle,
  ScrollableHeaderDescription,
  ScrollableHeaderContent,
  TextTooltip,
  InfoLabel,
  ModalMobileTitle,
  ModalMobileContent,
  MobileButton,
  ScrollableHeaderTitleContainer,
  // Scrollable row
  ButtonModal,
  TitleModalHeader,
  ContainerField,
  NameRow,
  TitleRegistrationManual,
  ScrollableRowContainer,
  ScrollableRowContent,
  DotButton,
  ContainerTitleLeft,
  ContainerTitle,
  TitleModal,
  SubTitleModal,
  ModalEdit,
  MaskedFieldTable,
  TextFieldTable,
  // Filter
  ContainerSelectedDates,
  ContainerData,
  TitleSelectedDates,
  ButtonClearFilter,
  SelectedDates,
  // Modal Filter
  ButtonFilter,
  ContainerDates,
  ContainerFilterModal,
  ContentDates,
  ContentFilterHeader,
  DateLabel,
  FooterDates,
  PopoverFilter,
}
