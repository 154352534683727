// TODO fazer as boas práticas da lib levi-ui nesse componente
// TODO remover eslint-disables e corrigir os erros apontados pelo lint
// TODO criar testes para esse componente

/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo } from 'react'

import {
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Line,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts'
import { theme } from '@cockpit/levi-ui/theme'
import {
  EFormatName,
  formatAgeTooltip,
  formatName,
  getDefaultLines,
  getDefaultUnit,
  linePercentil,
  lineZscore,
  monthsReference,
  roundedMonth,
} from './utils'

import {
  Container,
  Footer,
  LegendCapsule,
  LegendContainer,
  LegendContent,
  Source,
  Text,
  TooltipContainer,
} from './styles'
import { EAnthropometryChartType, IAnthropometryChart, IPercentil, IZScore } from './typing'

const Legend: React.FC<{ color: string; label: string }> = ({ color, label }) => {
  return (
    <LegendContent>
      <LegendCapsule color={color} />
      {label}
    </LegendContent>
  )
}

const AnthropometryChart: React.FC<IAnthropometryChart> = ({
  unit,
  width = '100%',
  aspect = 1.5,
  xAxisShort = false,
  sex,
  type,
  defaultLines,
  patientData,
  showMonthLine = false,
}) => {
  const data = useMemo(() => {
    const renderDefaultLines = getDefaultLines({ sex, type, defaultLines })

    const result = [...renderDefaultLines, ...patientData]
      .sort((a, b) => a.month - b.month)
      .map(item => {
        const patient = patientData.find(dataPatient => dataPatient.month === item.month)
        return {
          ...item,
          ...patient,
        }
      })
      .reduce((previous, current) => {
        const monthAlreadyExists = previous.find(
          (previousItem: { month: number }) => previousItem.month === current.month,
        )
        return monthAlreadyExists ? [...previous] : [...previous, current]
      }, [] as any)

    return result as IPercentil[] | IZScore[]
  }, [patientData, defaultLines, sex, type])

  const ageAboveFiveYears = useMemo(() => {
    const mappedData = data
      .map(item => {
        // 5 years
        if (item.month > 60) return true
        return false
      })
      .filter(item => item)
      .find(item => item)
    return mappedData
  }, [data])

  const defaultUnit = useMemo(() => {
    return unit ?? getDefaultUnit(defaultLines).unit
  }, [defaultLines, unit])

  return (
    <Container>
      <ResponsiveContainer width={width} aspect={aspect} debounce={300}>
        <LineChart data={data}>
          {/* Tooltip de informação do gráfico */}
          <Tooltip
            content={({ payload, label }: any) => {
              if (Array.isArray(payload)) {
                return (
                  <TooltipContainer>
                    {payload
                      .filter((entry: { name: EFormatName }) => entry.name === EFormatName.patient)
                      ?.map((entry: { color: any; name: EFormatName; value: any }, index: any) => (
                        <div key={String(index)} style={{ color: entry.color }} className="patient">
                          {`${formatName(entry.name)}: ${entry.value}`} {defaultUnit}
                        </div>
                      ))}
                    <span>{formatAgeTooltip(label)}</span>
                    {payload.map(
                      (entry: { color: any; name: EFormatName; value: any }, index: any) => {
                        if (entry.name !== EFormatName.patient) {
                          return (
                            <div key={String(index)} style={{ color: entry.color }}>
                              {`${formatName(entry.name)}: ${entry.value}`} {defaultUnit}
                            </div>
                          )
                        }

                        return <div />
                      },
                    )}
                  </TooltipContainer>
                )
              }

              return <div />
            }}
          />

          {/* Linha estática para cada ano */}
          {monthsReference.map(item => (
            <ReferenceLine x={item} stroke="#DEE1E8" />
          ))}

          {/* Linhas pontilhadas */}
          <CartesianGrid strokeDasharray="5 5" stroke="#DEE1E8" vertical={!ageAboveFiveYears} />

          {/* Estilizações do eixo Y */}
          <YAxis yAxisId="right" orientation="right" stroke="#DEE1E8" />
          <YAxis
            tickLine={false}
            stroke="#DEE1E8"
            tick={({ x, y, payload: { value } }) => (
              <Text x={x - 20} y={Number(y) + 5} textAnchor="middle">
                {value} {defaultUnit}
              </Text>
            )}
          />
          {type === EAnthropometryChartType.zScore &&
            lineZscore.map(item => (
              <Line
                connectNulls
                key={item.dataKey}
                type="monotone"
                dataKey={item.dataKey}
                stroke={item.stroke}
                dot={item.dataKey === 'patient'}
                strokeWidth={1.5}
              />
            ))}
          {type === EAnthropometryChartType.percentil &&
            linePercentil.map(item => (
              <Line
                connectNulls
                key={item.dataKey}
                type="monotone"
                dataKey={item.dataKey}
                stroke={item.stroke}
                dot={item.dataKey === 'patient'}
                strokeWidth={1.5}
              />
            ))}
          {/* Estilizações do eixo X (Meses) */}
          {!ageAboveFiveYears && (
            <XAxis
              dataKey="month"
              tickLine={false}
              stroke="#DEE1E8"
              interval={0}
              tick={({ x, y, payload: { value } }) => {
                // FIXME consertar bug caso exista um dado de um paciente com valor entre os meses fixos de 1 a 11, a linha é gerada
                // e permanece invisível
                if (Number.isInteger(value / 12) || !Number.isInteger(value)) {
                  return <div />
                }
                if (showMonthLine && value > 0) {
                  return (
                    <Text x={x} y={Number(y) + 10} textAnchor="middle">
                      {roundedMonth(value)}
                    </Text>
                  )
                }
                return <div />
              }}
            />
          )}
          {/* Estilizações do eixo X (Anos) */}
          <XAxis
            dataKey="month"
            xAxisId={!ageAboveFiveYears ? 'year' : undefined}
            tickLine={false}
            stroke="#DEE1E8"
            interval={0}
            tick={({ x, y, payload: { value } }) => {
              if (value === 0) {
                return (
                  <Text x={x} y={Number(y) + 10} textAnchor="middle">
                    Nasc
                  </Text>
                )
              }
              if (Number.isInteger(value / 12)) {
                return (
                  <Text x={x} y={Number(y) + 10} textAnchor="middle">
                    {value / 12}
                    {xAxisShort ? 'a' : ` ano${value / 12 > 1 ? 's' : ''}`}
                  </Text>
                )
              }
              return <div />
            }}
          />
        </LineChart>
      </ResponsiveContainer>
      <Footer>
        {type === EAnthropometryChartType.zScore && (
          <LegendContainer>
            <Legend color={theme.colors.neutral[80]} label="Paciente" />
            <Legend color={theme.colors.red[40]} label="-2" />
            <Legend color={theme.colors.yellow[30]} label="-1" />
            <Legend color={theme.colors.green[40]} label="0" />
            <Legend color={theme.colors.yellow[30]} label="1" />
            <Legend color={theme.colors.red[40]} label="2" />
          </LegendContainer>
        )}
        {type === EAnthropometryChartType.percentil && (
          <LegendContainer>
            <Legend color={theme.colors.neutral[80]} label="Paciente" />
            <Legend color={theme.colors.red[40]} label="3%" />
            <Legend color={theme.colors.yellow[30]} label="15%" />
            <Legend color={theme.colors.green[40]} label="50%" />
            <Legend color={theme.colors.yellow[30]} label="85%" />
            <Legend color={theme.colors.red[40]} label="97%" />
          </LegendContainer>
        )}
        <Source>Fonte: OMS 2006/2007</Source>
      </Footer>
    </Container>
  )
}
export default AnthropometryChart
