import { theme } from '@cockpit/levi-ui/theme'
import styled from '@emotion/styled'

export const Container = styled.div`
  background: #ffffff;
  align-items: center;
  justify-content: center;
  padding: 20px;

  .recharts-wrapper .recharts-cartesian-grid-horizontal line {
    stroke-dasharray: 0 !important;
  }
`

export const Footer = styled.div``

export const LegendContent = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 12px;
  color: ${theme.colors.neutral[60]};
`
export const LegendContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
`

export const Source = styled.div`
  display: flex;
  margin-top: 12px;
  margin-right: 48px;
  justify-content: flex-end;
  font-size: 12px;
  color: ${theme.colors.neutral[60]};
`

export const LegendCapsule = styled.div<{ color: string }>`
  width: 24px;
  height: 6px;
  border-radius: 12px;
  background-color: ${({ color }) => color};
`

export const Text = styled.text`
  text-align: center;
  font: normal normal normal 12px/15px Inter;
  color: #7c828a;
  fill: #7c828a;
`

export const TooltipContainer = styled.div`
  background-color: #ffffff;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%),
    0 2px 4px -1px rgb(0 0 0 / 30%);
  > span {
    font-size: 14px;
    display: block;
    color: #333333;
    margin-bottom: 20px;
  }
  > div {
    font-size: 14px;
    margin-bottom: 10px;
    &.patient {
      margin-bottom: 5px;
    }
  }
`
